import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconWeb,
    IconYoutube,
} from '../SVG/Icons';
import './styles.scss';

function Sentimental({ title = '', data = [], sociais = [] }) {
    const [newData, setNewData] = useState([]);

    useEffect(() => {
        const dataFilter = [];

        sociais.map((row, key) => {
            var chart = [
                {
                    title: 'Positivo',
                    value: data.filter(
                        (obj) =>
                            obj.network === row.toLowerCase() &&
                            obj.sentiment === 'positive'
                    ).length,
                    color: '#6ee56e',
                },
                {
                    title: 'Negativo',
                    value: data.filter(
                        (obj) =>
                            obj.network === row.toLowerCase() &&
                            obj.sentiment === 'negative'
                    ).length,
                    color: '#ff9d9d',
                },
                {
                    title: 'Neutro',
                    value: data.filter(
                        (obj) =>
                            obj.network === row.toLowerCase() &&
                            obj.sentiment === 'neutral'
                    ).length,
                    color: '#cdcdcd',
                },
            ];

            if (
                data.filter((obj) => obj.network === row.toLowerCase()).length
            ) {
                dataFilter.push({
                    title: row,
                    chart,
                });
            }
        });

        setNewData(dataFilter);
    }, [data, sociais]);

    return (
        <div className="chart-sentimental">
            <div className="title-post">
                <h3>{title}</h3>
            </div>
            <div className="data">
                {newData.map((row, key) => (
                    <div className="item" key={key}>
                        <div className="header">
                            {row.title === 'Facebook' && <IconFacebook />}
                            {row.title === 'Twitter' && <IconTwitter />}
                            {row.title === 'Youtube' && <IconYoutube />}
                            {row.title === 'Instagram' && <IconInstagram />}
                            {row.title === 'Web' && <IconWeb />}
                            <p>{row.title}</p>
                        </div>

                        <div className="chart">
                            <PieChart
                                data={row.chart}
                                label={({ dataEntry }) =>
                                    `${Math.round(dataEntry.percentage)} %`
                                }
                                animate={true}
                                labelStyle={{
                                    fontSize: '5px',
                                }}
                            />

                            <div className="legends">
                                {row.chart.map((resposta, respostaKey) => (
                                    <div className="legend">
                                        <i
                                            style={{
                                                backgroundColor: resposta.color,
                                            }}
                                        ></i>
                                        <span>
                                            {resposta.title} ({resposta.value})
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Sentimental;
