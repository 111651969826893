import React from 'react';
import InputDefault from '../Form/InputDefault';

// import { Container } from './styles';

function Configuracao({DTO, handleOnChange}) {
  return (
    <div className="form-data col">
        
        <ul className="info">
          <li>O nome da campanha é importante para poder identifica-la e consumir os relatórios da mesma.</li>
        </ul>

        <InputDefault
            label="Nome da campanha"
            value={DTO.nome}
            name="nome"
            onChange={(name, value) => handleOnChange(name, value) }
        /> 
    </div>
  );
}

export default Configuracao;