
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Skeleton from 'react-loading-skeleton';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { RequestAPIRecomeca } from '../../Api/appRecomeca';
import ButtonDefault from '../../Components/Form/ButtonDefault';
import Paginator from '../../Components/Paginator/Paginator';
import Datetime from '../../Helper/Datetime';

//components
import { useGlobal } from '../../Context/GlobalContext';

//scss
import './RecomecaRJ.scss';

export default function RecomecaRJ(props) {
    const { changePage } = useGlobal();
    const refDownload = useRef();
    const [pageList, setPageList] = useState(1);
    const [loadDownload, setLoadDownload] = useState(true);
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    const [dataInicio, setDataInicio] = useState(Datetime.today());
    const [dataFinal, setDataFinal] = useState(Datetime.today());
    const [list, setList] = useState({
        DadosRetirada: [],
    });
    const [dataDownload, setDataDownload] = useState([]);
    const [dataChart, setDataChart] = useState([
        {
            name: '...',
            Quantidade: 0,
        },
        {
            name: '...',
            Quantidade: 0,
        },
    ]);
    const [selected, setSelected] = useState({
        frente: {
            Foto: '',
        },
        back: {
            Foto: '',
        },
        doc: {
            Foto: '',
        }
    });

    useEffect(() => {
        changePage('name', 'Recomeçar RJ');
        getDataChart();
    }, [props]);

    useEffect(() => {
        getDataPaginate();
    }, [pageList]);

    useEffect(() => {
        handleDownloadCSV();
    }, [dataInicio, dataFinal]);

    async function handleDownloadCSV() {
        setLoadDownload(true);
        const response = await RequestAPIRecomeca.get(
            `RelatorioRetirada?DataInicio=${dataInicio}&DataFim=${dataFinal}`
        );
        setDataDownload([...response.data.ObjRetorno]);
        setLoadDownload(false);
    }

    async function getDataChart() {
        const newData = [];
        const response = await RequestAPIRecomeca.get('GraficoRetirada');
        response.data.ObjRetorno.map((row) => {
            newData.push({
                name: row.DataRetirada,
                Quantidade: parseInt(row.Quantidade),
            });
        });
        setDataChart([...newData]);
    }

    async function getDataPaginate(load = false) {
        setLoad(true);
        const response = await RequestAPIRecomeca.post('RelatorioRetiradaPaginado', {
            Pagina: pageList,
            Quantidade: 10,
            DataInicio: dataInicio,
            DataFinal: dataFinal,
        });
        setList(response.data.ObjRetorno);
        setLoad(false);
    }

    async function handleSelectRegister(Id) {
        setSelected({ frente: {}, back: {}, doc: {}, Id: Id });

        const response = await RequestAPIRecomeca.post('BuscaImagemRetirada', {
            Id: Id,
            foto: '1',
        });

        const responseBack = await RequestAPIRecomeca.post('BuscaImagemRetirada', {
            Id: Id,
            foto: '2',
        });

        const responseDoc = await RequestAPIRecomeca.post('BuscaImagemRetirada', {
            Id: Id,
            foto: '3',
        });

        console.log('response.data', response.data)
        console.log('responseBack.data', responseBack.data)
        console.log('responseDoc.data', responseDoc.data)

        setSelected({
            frente: response.data.ObjRetorno,
            back: responseBack.data.ObjRetorno,
            doc: responseDoc.data.ObjRetorno,
            visible: true,
        });
    }

    return (
        <div id="RecomecaRJ">
            <div id="chart">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={dataChart}>
                        <XAxis stroke="rgba(255,255,255,0.5)" dataKey="name" />
                        <YAxis stroke="rgba(255,255,255,0.5)" />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="Quantidade"
                            stroke="#8884d8"
                            fill="#8884d8"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>

            <div className="table-default">
                <div className="head">
                    <p>Retiradas</p>

                    <form>
                        <span>Exportar listagem</span>
                        <input
                            placeholder="Data inicial"
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e.target.value)}
                            type="date"
                        />
                        <span>até</span>
                        <input
                            placeholder="Data final"
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e.target.value)}
                            type="date"
                        />

                        {loadDownload === false && (
                            <CSVLink
                                filename={'Recomeçar RJ.csv'}
                                data={dataDownload}
                                ref={refDownload}
                                headers={[
                                    {
                                        label: 'Nome do atendente',
                                        key: 'NomeAtendente',
                                    },
                                    {
                                        label: 'CPF',
                                        key: 'CPF',
                                    },
                                    {
                                        label: 'Nome do Municipe',
                                        key: 'NomeMunicipe',
                                    },
                                    {
                                        label: 'Data da retirada',
                                        key: 'DataRetirada',
                                    },
                                ]}
                            >
                                <ButtonDefault
                                    type="button"
                                    onClick={handleDownloadCSV}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        role="img"
                                        width="1em"
                                        height="1em"
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 1024 1024"
                                    >
                                        <path
                                            d="M624 706.3h-74.1V464c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v242.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.7a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M811.4 366.7C765.6 245.9 648.9 160 512.2 160S258.8 245.8 213 366.6C127.3 389.1 64 467.2 64 560c0 110.5 89.5 200 199.9 200H304c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8h-40.1c-33.7 0-65.4-13.4-89-37.7c-23.5-24.2-36-56.8-34.9-90.6c.9-26.4 9.9-51.2 26.2-72.1c16.7-21.3 40.1-36.8 66.1-43.7l37.9-9.9l13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0 1 52.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9c15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5l37.8 10C846.1 454.5 884 503.8 884 560c0 33.1-12.9 64.3-36.3 87.7a123.07 123.07 0 0 1-87.6 36.3H720c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h40.1C870.5 760 960 670.5 960 560c0-92.7-63.1-170.7-148.6-193.3z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </ButtonDefault>
                            </CSVLink>
                        )}
                    </form>
                </div>

                <table>
                    <tr>
                        <th></th>
                        <th>Nome do atendente</th>
                        <th>CPF</th>
                        <th>Nome Municipe</th>
                        <th>Data de retirada</th>
                    </tr>

                    {load === true &&
                        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((row, key) => (
                            <tr>
                                <td colSpan={5}>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                    {load === false &&
                        list.DadosRetirada.map((row, key) => (
                            <tr key={key}>
                                <td>
                                    <ButtonDefault
                                        onClick={() =>
                                            handleSelectRegister(row.Id)
                                        }
                                    >
                                        {selected.Id === row.Id ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                role="img"
                                                width="1em"
                                                height="1em"
                                                preserveAspectRatio="xMidYMid meet"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    cx="18"
                                                    cy="12"
                                                    r="0"
                                                    fill="currentColor"
                                                >
                                                    <animate
                                                        attributeName="r"
                                                        values="0;2;0;0"
                                                        dur="1.5s"
                                                        repeatCount="indefinite"
                                                        begin=".67"
                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                        calcMode="spline"
                                                    />
                                                </circle>
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="0"
                                                    fill="currentColor"
                                                >
                                                    <animate
                                                        attributeName="r"
                                                        values="0;2;0;0"
                                                        dur="1.5s"
                                                        repeatCount="indefinite"
                                                        begin=".33"
                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                        calcMode="spline"
                                                    />
                                                </circle>
                                                <circle
                                                    cx="6"
                                                    cy="12"
                                                    r="0"
                                                    fill="currentColor"
                                                >
                                                    <animate
                                                        attributeName="r"
                                                        values="0;2;0;0"
                                                        dur="1.5s"
                                                        repeatCount="indefinite"
                                                        begin="0"
                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                                        calcMode="spline"
                                                    />
                                                </circle>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                role="img"
                                                width="1em"
                                                height="1em"
                                                preserveAspectRatio="xMidYMid meet"
                                                viewBox="0 0 1024 1024"
                                            >
                                                <path
                                                    d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm144 452H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm445.7 51.5l-93.3-93.3C814.7 780.7 828 743.9 828 704c0-97.2-78.8-176-176-176s-176 78.8-176 176s78.8 176 176 176c35.8 0 69-10.7 96.8-29l94.7 94.7c1.6 1.6 3.6 2.3 5.6 2.3s4.1-.8 5.6-2.3l31-31a7.9 7.9 0 0 0 0-11.2zM652 816c-61.9 0-112-50.1-112-112s50.1-112 112-112s112 50.1 112 112s-50.1 112-112 112z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        )}
                                    </ButtonDefault>
                                </td>
                                <td>{row.NomeAtendente}</td>
                                <td>{row.CPF}</td>
                                <td>{row.NomeMunicipe}</td>
                                <td>{row.DataRetirada}</td>
                            </tr>
                        ))}

                    {load === false && list.DadosRetirada.length === 0 && (
                        <tr>
                            <td colSpan={5}>Nenhum registro encontrado.</td>
                        </tr>
                    )}

                    <tfoot>
                        <tr>
                            <td colSpan={4}>
                                <Paginator
                                    page={pageList}
                                    setPage={setPageList}
                                    range={4}
                                    totalDocs={list.Total}
                                    totalPage={list.Pages}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div
                className={`modal-overlay ${
                    selected.visible ? 'active' : 'inactive'
                }`}
            >
                <button
                    className="close"
                    onClick={() => setSelected({ frente: {}, back: {}, doc: {} })}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 32 32"
                    >
                        <g
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        >
                            <path d="M2 30L30 2m0 28L2 2" />
                        </g>
                    </svg>
                </button>
                <div className="img">
                    <div>
                        <img src={'data:image/jpeg;base64,' + selected.frente.Foto}/>
                        <h3>Rosto</h3>
                    </div>
                    <div>
                        <img src={'data:image/jpeg;base64,' + selected.back.Foto} />
                        <h3>Termo</h3>
                    </div>
                    <div>
                        <img src={'data:image/jpeg;base64,' + selected.doc.Foto} />
                        <h3>Documento</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
