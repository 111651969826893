import React from 'react';

export default function SelectDefault({
    label,
    type = 'text',
    placeholder,
    name,
    value,
    mask,
    maskChar,
    required,
    children,
    onChange = () => {},
}) {
    return (
        <label className="input-default">
            {label && <span>{label}</span>}

            <select
                type={type}
                onChange={(e) => onChange(name, e.target.value)}
                value={value}
                placeholder={placeholder}
                mask={mask}
                maskChar={maskChar}
                required={required}
            >
                {children}
            </select>
        </label>
    );
}
