import React from 'react';
import ButtonDefault from '../Form/ButtonDefault';
import InputUpload from '../Form/InputUpload';

// import { Container } from './styles';

function BaseDados({DTO, handleOnChange}) {
  return (
    <div className="form-data col base-dados">

        <ul className="info">
          <li>A sua lista de contatos deve seguir um padrão para que possamos identificar todos os campos corretamente, <a>aqui</a> você pode baixar um CSV de exemplo</li>
        </ul>
        
        <div className="row input-proccess">
          <InputUpload
              label="Arquivo CSV"
              type="file"
              onChange={(e) => console.log(e)}
          />
        </div>

    </div>
  );
}

export default BaseDados;