import React, { useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, Circle, DrawingManager } from "react-google-maps"
import axios from 'axios'
const API_KEY = 'AIzaSyDNnbvC6YiCzjPVTS2n1_S3OnLQXzXk49o'

const MapContainer = withScriptjs(withGoogleMap((props) => {

    const center = { lat: -22.7853508, lng: -43.4397457, }

    async function handleAddMarker(e)
    {

        const DTO = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
        const address = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${DTO.lat},${DTO.lng}&key=${API_KEY}&sensor=false`)
        
        if ( address.data.results.length > 0 ) {
            const addressFormated = address.data.results[0]
            DTO.estado = getInfoByType(addressFormated, 'administrative_area_level_1').short_name
            DTO.cidade = getInfoByType(addressFormated, 'administrative_area_level_2').long_name
            DTO.bairro = getInfoByType(addressFormated, 'sublocality_level_1').long_name
            DTO.rua = getInfoByType(addressFormated, 'route').long_name
            props.setFormLocal(DTO)
        }


    }

    function getInfoByType(address, type)
    {

        const search = address.address_components.filter((obj) => 
            obj.types.filter((typeFilter) => typeFilter === type).length > 0
        )

        return search.length > 0 ? search[0] : {
            long_name: '',
            short_name: ''
        }

    }

    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={center}
            clickableIcons={true}
            onClick={(e) => handleAddMarker(e)}
            options={{streetViewControl: false, mapTypeControl: false, disableDefaultUI: false}}
        >
            { props.markers.map((row, key) => 
                <Marker key={key} defaultPosition={row}></Marker>
            )}
        </GoogleMap>
    )

}))

export { MapContainer}