import React, { useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars";

import Profile from "./Profile";
import SideBar from "./Sidebar";

import "./Layout.scss";
import { useGlobal } from "../../Context/GlobalContext";

export default function Layout(props) {
  const { page } = useGlobal();
  const { user } = useGlobal()

  const [menuOpened, setMenuOpened] = useState(true);

  const [menus, setMenus] = useState([])

  useEffect(() => {


    if ( user.NivelAcesso === 'Administrador' ) {
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
      ])
    } else if ( user.NivelAcesso === 'Usuario' ){
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
      ])
    } else if ( user.NivelAcesso === 'Recupera' ){
      setMenus([
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4z"
                fill="currentColor"
              />
              <path d="M24 21h2v5h-2z" fill="currentColor" />
              <path d="M20 16h2v10h-2z" fill="currentColor" />
              <path d="M16 18h2v8h-2z" fill="currentColor" />
              <path
                d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm-16 9H4V4h8zm2 0V4h14v7z"
                fill="currentColor"
              />
            </svg>
          ),
          name: "Dashboard",
          to: "/",
        },
      ])
    }

  }, [user])

  return (
    <div id="layout">
      <div className={`sidebar ${menuOpened === true ? "opened" : "closed"}`}>
        <div className={`head`}>
          <a className="logo-default">Renato Miranda</a>

          <button onClick={() => setMenuOpened(!menuOpened)}>
            {!menuOpened ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M16 5H0V4h16v1zm0 8H0v-1h16v1zm0-4.008H0V8h16v.992z" />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M764.288 214.592L512 466.88L259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512L214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
                />
              </svg>
            )}
          </button>
        </div>

        <SideBar
          pathname={props.location.pathname}
          data={menus}
        />
      </div>

      <div className="content">
        <header
          id="content-header"
          className={`${page.name}`}
        >
          <h1>{page.name}</h1>

          <div className="last">
            <Profile />
          </div>
        </header>

        <section id="content-page">{props.children}</section>
      </div>
    </div>
  );
}
