import React from 'react';
import Post from './Post';

// import { Container } from './styles';

function Posts({ data = [], title }) {
    return (
        <div>
            <div className="title-post">
                <h3>
                    {title} {data.length}
                </h3>
            </div>
            <div className="posts">
                {data.map((row, key) => (
                    <Post item={row} key={key} />
                ))}
            </div>
        </div>
    );
}

export default Posts;
