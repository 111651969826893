import React from 'react'
import { IconFacebook, IconGPS, IconInstagram, IconLink, IconTwitter, IconWeb, IconYoutube } from '../../Components/SVG/Icons'
import Datetime from '../../Helper/Datetime'

import "./Post.scss"

export default function Post({item})
{

    return(
        <div className={`box-post ${item.sentiment}`}>
                                
            <div className="container-box">
                <div className="icon">
                    { item.network === 'facebook' && <IconFacebook/> }
                    { item.network === 'twitter' && <IconTwitter/> }
                    { item.network === 'youtube' && <IconYoutube/> }
                    { item.network === 'instagram' && <IconInstagram/> }
                    { item.network === 'web' && <IconWeb/> }
                </div>

                <div className="head-info">

                    <div className="photo" style={{backgroundImage: `url(${item.user.image ? item.user.image : item.image})`}}></div>

                    <div className="infos">
                        <span>
                            <b>{ item.user.name }</b>
                        </span>
                        <span>
                            { Datetime.string(item.posted.replace('+00000', '')) }
                        </span>
                    </div>
                </div>

                { item.type === 'photo' && 
                    <div className="image">
                        <img src={item.image}/>
                    </div>
                }

                <div className="text">
                    { item.text }
                </div>

                <div className="extra-info">
                    { item.user.location && 
                        <div>
                            <IconGPS/>
                            <span>{item.user.location}</span>
                        </div>
                    }
                    <a target="_blank" href={item.url}>
                        <IconLink/>
                        <span>{item.url}</span>
                    </a>
                </div>

                { item.tags && item.tags?.length > 0 && 
                    <div className="tags">
                        { item.tags.map((tag, tagkey) =>
                            <a key={tagkey} href={tag.url}>{tag.text}</a>
                        )}
                    </div>
                }

                { item.popularity && 
                    <div className="popularity">
                        { item.popularity.map((row, key) =>
                            <div key={key}>
                                <span>{row.name}</span>
                                <b>{row.count}</b>
                            </div>
                        )}
                    </div>
                }

                { item.sentiment && 
                    <div className="sentiment">
                        <div className={`${item.sentiment === 'negative' ? 'negative' : 'inactive'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 40 40"><path d="M20.001 2.683C10.452 2.683 2.684 10.451 2.684 20s7.769 17.317 17.317 17.317S37.316 29.548 37.316 20S29.549 2.683 20.001 2.683zm0 33.134c-8.722 0-15.817-7.096-15.817-15.817S11.279 4.183 20.001 4.183c8.721 0 15.815 7.096 15.815 15.817s-7.094 15.817-15.815 15.817z" fill="currentColor"/><path d="M20.013 22.697a7.726 7.726 0 0 0-6.604 3.682a.375.375 0 0 0 .122.516l.572.353a.375.375 0 0 0 .515-.122a6.304 6.304 0 0 1 5.394-3.011a6.305 6.305 0 0 1 5.374 2.979a.376.376 0 0 0 .516.118l.568-.356a.372.372 0 0 0 .118-.516a7.72 7.72 0 0 0-6.575-3.643z" fill="currentColor"/><circle cx="15.431" cy="16.005" r="1.044" fill="currentColor"/><circle cx="24.568" cy="16.005" r="1.044" fill="currentColor"/></svg>
                        </div>
                        <div className={`${item.sentiment === 'neutral' ? 'neutral' : 'inactive'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 40 40"><path d="M20.001 2.683C10.452 2.683 2.684 10.451 2.684 20s7.769 17.317 17.317 17.317S37.316 29.548 37.316 20S29.549 2.683 20.001 2.683zm0 33.134c-8.722 0-15.817-7.096-15.817-15.817S11.279 4.183 20.001 4.183c8.721 0 15.815 7.096 15.815 15.817s-7.094 15.817-15.815 15.817z" fill="currentColor"/><circle cx="15.431" cy="16.424" r="1.044" fill="currentColor"/><circle cx="24.569" cy="16.424" r="1.044" fill="currentColor"/><path d="M24.828 25.312h-9.656a.75.75 0 0 0 0 1.5h9.656a.75.75 0 0 0 0-1.5z" fill="currentColor"/></svg>
                        </div>
                        <div className={`${item.sentiment === 'positive' ? 'positive' : 'inactive'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 40 40"><path d="M20.001 2.683C10.452 2.683 2.684 10.451 2.684 20s7.769 17.317 17.317 17.317S37.316 29.548 37.316 20S29.549 2.683 20.001 2.683zm0 33.134c-8.722 0-15.817-7.096-15.817-15.817S11.279 4.183 20.001 4.183c8.721 0 15.815 7.096 15.815 15.817s-7.094 15.817-15.815 15.817z" fill="currentColor"/><path d="M26.472 23.655l-.568-.356a.364.364 0 0 0-.283-.048a.37.37 0 0 0-.233.166a6.308 6.308 0 0 1-5.374 2.979a6.304 6.304 0 0 1-5.394-3.01a.376.376 0 0 0-.516-.122l-.572.353a.375.375 0 0 0-.122.516a7.724 7.724 0 0 0 6.604 3.681a7.722 7.722 0 0 0 6.577-3.642a.375.375 0 0 0-.119-.517z" fill="currentColor"/><circle cx="15.265" cy="15.516" r="1.044" fill="currentColor"/><circle cx="24.403" cy="15.516" r="1.044" fill="currentColor"/></svg>
                        </div>
                    </div>
                }

            </div>

        </div>
    )

}