import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//components
import messageStore from '../../Store/MessageStore';
import { useGlobal } from '../../Context/GlobalContext';

//scss
import './MonitoramentoSocial.scss';
import LoadingGif from '../../Images/loadinggif.gif';
import InputDefault from '../../Components/Form/InputDefault';
import ButtonDefault from '../../Components/Form/ButtonDefault';
import Axios from 'axios';
import {
    IconFacebook,
    IconInstagram,
    IconRecent,
    IconTrends,
    IconTwitter,
    IconWeb,
    IconYoutube,
} from '../../Components/SVG/Icons';
import Post from '../../Components/Post/Post';
import Posts from '../../Components/Post/Posts';
import Sentimental from '../../Components/Sentimental';

export default function Profile(props) {
    const history = useHistory();
    const [submited, setSubmited] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('postagens');
    const { changePage, setUser, user } = useGlobal();
    const [load, setLoad] = useState(false);
    const [sociais, setSociais] = useState([
        'Facebook',
        'Twitter',
        'Youtube',
        'Instagram',
        'Web',
    ]);
    const [dataPosts, setDataPosts] = useState([]);
    const [dataPostsTrends, setDataPostsTrends] = useState([]);

    useEffect(() => {
        changePage('name', 'Social Monitoring');
    }, []);

    function handleChangeSocial(name) {
        if (sociais.filter((obj) => obj === name).length) {
            setSociais([...sociais.filter((obj) => obj != name)]);
        } else {
            sociais.push(name);
            setSociais([...sociais]);
        }
    }

    const getTrends = async (network) => {
        const response = await Axios.get(
            `https://api.social-searcher.com/v2/trends?key=a0aa73cf057d108d11788e9b2bd58feb&q=${search}&network=${network.toLowerCase()}`
        );

        return Promise.resolve({
            network: network.toLowerCase(),
            posts: response.data.posts ? response.data.posts : [],
        });
    };

    const getPosts = async (network) => {
        const response = await Axios.get(
            `https://api.social-searcher.com/v2/search?key=a0aa73cf057d108d11788e9b2bd58feb&q=${search}&network=${network.toLowerCase()}`
        );

        return Promise.resolve({
            network: network.toLowerCase(),
            posts: response.data.posts ? response.data.posts : [],
        });
    };

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            const posts = [];
            const postsTrends = [];

            setLoad(true);

            const dataPosts = await Promise.all(
                sociais.map((row) => getPosts(row))
            );
            dataPosts.map((row) => {
                row.posts.map((post) => {
                    posts.push(post);
                });
            });
            setDataPosts([
                ...posts.sort((a, b) =>
                    a.posted > b.posted ? -1 : Number(a.posted < b.posted)
                ),
            ]);

            const dataTrends = await Promise.all(
                sociais.map((row) => getTrends(row))
            );
            dataTrends.map((row) => {
                row.posts.map((post) => {
                    postsTrends.push(post);
                });
            });
            setDataPostsTrends([
                ...postsTrends.sort((a, b) =>
                    a.posted > b.posted ? -1 : Number(a.posted < b.posted)
                ),
            ]);

            setLoad(false);
            setSubmited(true);
        } catch (err) {
            messageStore.addError(
                'Erro ao consultar, tente novamente mais tarde.'
            );
            setLoad(false);
        }
    }

    return (
        <div id="monitoramento-social">
            <form className="search-social" onSubmit={handleSubmit}>
                <div className="social-list">
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Facebook')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Facebook').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconFacebook />
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Twitter')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Twitter').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconTwitter />
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Youtube')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Youtube').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconYoutube />
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Instagram')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Instagram').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconInstagram />
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Web')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Web').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconWeb />
                    </button>
                </div>

                <div className="input-container">
                    <InputDefault
                        placeholder="Busque por assuntos, pessoas e interesses"
                        value={search}
                        onChange={(name, value) => setSearch(value)}
                    />
                    <ButtonDefault load={load}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="img"
                            width="1em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                        >
                            <g fill="none">
                                <path
                                    d="M21 21l-4.486-4.494M19 10.5a8.5 8.5 0 1 1-17 0a8.5 8.5 0 0 1 17 0z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    stroke-linecap="round"
                                />
                            </g>
                        </svg>
                    </ButtonDefault>
                </div>
            </form>

            {submited && (dataPosts.length > 0 || dataPostsTrends.length > 0) && (
                <div className="filter">
                    <button
                        onClick={() => setFilter('postagens')}
                        className={
                            filter === 'postagens' ? 'active' : 'inactive'
                        }
                    >
                        Postagens
                    </button>
                    <button
                        onClick={() => setFilter('sentimentos')}
                        className={
                            filter === 'sentimentos' ? 'active' : 'inactive'
                        }
                    >
                        Análise de sentimentos
                    </button>
                </div>
            )}

            {load === true && <img src={LoadingGif} />}

            {filter === 'sentimentos' && submited === true && load === false && (
                <>
                    <Sentimental
                        data={dataPosts}
                        title="Análise de sentimentos recentes"
                        sociais={sociais}
                    />
                    <Sentimental
                        data={dataPostsTrends}
                        title="Análise de sentimentos trends"
                        sociais={sociais}
                    />
                </>
            )}

            {filter === 'postagens' && submited === true && load === false && (
                <>
                    <Posts
                        load={load}
                        title="Postagens recentes"
                        data={dataPosts}
                    />
                    <Posts
                        load={load}
                        title="Postagens trends"
                        data={dataPostsTrends}
                    />
                </>
            )}
        </div>
    );
}
