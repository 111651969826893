const axios = require('axios');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = 'http://13.59.44.236:8969/api/'
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = 'http://13.59.44.236:8969/api/'
} else {
  baseUrl = 'http://13.59.44.236:8969/api/'
}

const TOKEN = window.localStorage.getItem('token')

const RequestAPIRecomeca = axios.create({
  baseURL: baseUrl,
  headers: {
    'Authorization': 'Bearer '+TOKEN
  }
})

export { RequestAPIRecomeca, TOKEN }