import React, { useEffect, useState } from 'react';
import { MapContainer } from '../GoogleMaps/MapContainer';
import { RequestAPI } from '../../Api/app'
import SelectDefault from '../Form/SelectDefault';
import InputDefault from '../Form/InputDefault';
import ButtonDefault from '../Form/ButtonDefault';

function GeoReferencia({DTO, handleOnChange}) {

  const [markers, setMarkers] = useState([])
  const [loading, setLoading] = useState(false)
  const [ObjRetorno, setObjRetorno] = useState([])

  const [formLocal, setFormLocal] = useState({
    estado: '',
    cidade: '',
    bairro: '',
    rua: '',
    idade: '',
    sexo: '',
    renda: ''
  })

  useEffect(() => {
    handleRemoveAddress('loadLocal')
  }, [])

  function handleOnChangeLocal(name, value)
  {
    formLocal[name] = value
    setFormLocal({...formLocal})
  }

  async function handleAddAddress(e)
  {

    try {

      e.preventDefault()
      setLoading(true)

      const DTOToApi = {
        Tipo: formLocal.rua ? '1' : '2',
        Dado: formLocal.rua ? formLocal.rua : formLocal.bairro,
        Idade: formLocal.idade,
        Sexo: formLocal.sexo,
        Renda: formLocal.renda
      }

      const response = await RequestAPI.post('Disponibilizacao', DTOToApi)
      setObjRetorno([...response.data.ObjRetorno])
      setLoading(false)
      setFormLocal({
        estado: '',
        cidade: '',
        bairro: '',
        rua: '',
        idade: '',
        sexo: '',
        renda: ''
      })

    } catch ( err ) {
      console.log('error', err)
      setLoading(false)
    }

  }

  async function handleRemoveAddress(address, tipo)
  {
    const DTOToApi = {
      Tipo: tipo === 'rua' ? '1' : '2',
      Dado: address,
    }
    const response = await RequestAPI.post('Disponibilizacao', DTOToApi)
    setObjRetorno([...response.data.ObjRetorno])
  }

  return (
    <div className="georeferencia">

      <div className="geo-maps">

        <form onSubmit={handleAddAddress} className="filters">
          <InputDefault placeholder="Estado" label="Estado" value={formLocal.estado} onChange={(name, value) => handleOnChangeLocal('estado', value)}/>
          <InputDefault placeholder="Cidade" label="Cidade" value={formLocal.cidade} onChange={(name, value) => handleOnChangeLocal('cidade', value)}/>
          <InputDefault placeholder="Bairro" label="Bairro" value={formLocal.bairro} onChange={(name, value) => handleOnChangeLocal('bairro', value)}/>
          <InputDefault placeholder="Rua" label="Rua" value={formLocal.rua} onChange={(name, value) => handleOnChangeLocal('rua', value)}/>
          <InputDefault placeholder="Idade" label="Idade" value={formLocal.idade} onChange={(name, value) => handleOnChangeLocal('idade', value)}/>
          <SelectDefault label="Sexo" value={formLocal.sexo} onChange={(name, value) => handleOnChangeLocal('sexo', value)}>
            <option value="">Selecione</option>
            <option>Masculino</option>
            <option>Feminino</option>
          </SelectDefault>
          <InputDefault placeholder="Renda" label="Renda" value={formLocal.renda} onChange={(name, value) => handleOnChangeLocal('renda', value)}/>
          <ButtonDefault load={loading}>Inserir</ButtonDefault>
        </form>

        { ObjRetorno.length > 0 &&
          <ul className="list-address">
            {ObjRetorno.map((row, key) => 
              <li>
                <fieldset>
                  <legend>{row.Rua ?? row.Bairro}</legend>
                  <div>
                    <span>Quantidade: {row.Qtde}</span>
                    <button type="button" onClick={() => handleRemoveAddress(row.Rua??row.Bairro, row.Rua ? 'rua' : 'bairro')}>Excluir</button>
                  </div>
                </fieldset>
              </li>
            )}
          </ul>
        }

        <div className="maps">
            <MapContainer
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNnbvC6YiCzjPVTS2n1_S3OnLQXzXk49o&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              formLocal={formLocal}
              setFormLocal={setFormLocal}
              markers={markers}
            />
        </div>
      </div>

    </div>
  );
}

export default GeoReferencia;