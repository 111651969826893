import { useEffect, useState } from 'react'
import React from 'react'

const Paginator = ({setPage, page, totalPage = 0, range, totalDocs}) => {

    const [data, setData] = useState([])

    useEffect(() => {

        const newData = []

        for (var i = (page - range); i < (page + range); i++) {

            try {

                if ( i <= 0 ) throw i
                if ( i > totalPage ) throw i

                newData.push(i)

            } catch ( e ){


            }

        }

        setData([...newData])

    }, [page, totalPage])

    return(
        <div className="paginator">
            
            { data.length > 0 && 
                <p>{totalDocs} registros</p>
            }
            
            { data.length > 0 &&
                <ul>
                    <li>
                        <button className={page === 1 ? 'inactive' : 'normal'} type="button" onClick={() => page != 1 && setPage(1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/><path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></g></svg>
                        </button>
                    </li>
                    {data.map((row, key) =>
                        <li key={key}>
                            <button className={page === row ? 'active' : 'normal'} type="button" onClick={() => row != page && setPage(row)}>{row}</button>
                        </li>
                    )}
                    <li>
                        <button className={page === totalPage ? 'inactive' : 'normal'} type="button" onClick={() => page != totalPage && setPage(totalPage)}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8L3.646 2.354a.5.5 0 0 1 0-.708z"/><path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8L7.646 2.354a.5.5 0 0 1 0-.708z"/></g></svg>
                        </button>
                    </li>
                </ul>
            }

        </div>
    )

}

export default Paginator