import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

//scss
import './MonitoramentoAutomatizado.scss';
import { RequestAPI } from '../../Api/app';
import { useGlobal } from '../../Context/GlobalContext';
import {
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconWeb,
    IconYoutube,
} from '../../Components/SVG/Icons';
import InputDefault from '../../Components/Form/InputDefault';
import SelectDefault from '../../Components/Form/SelectDefault';
import ButtonDefault from '../../Components/Form/ButtonDefault';
import Paginator from '../../Components/Paginator/Paginator';
import Skeleton from 'react-loading-skeleton';
import Datetime from '../../Helper/Datetime';

export default function MonitoramentoAutomatizado(props) {
    const history = useHistory();
    const { changePage, setUser, user } = useGlobal();
    const [search, setSearch] = useState('');
    const [searchName, setSearchName] = useState('');
    const [load, setLoad] = useState(false);
    const [dataInicio, setDataInicio] = useState(Datetime.today());
    const [dataFinal, setDataFinal] = useState(Datetime.today());
    const [sociais, setSociais] = useState([
        'Facebook',
        'Twitter',
        'Youtube',
        'Instagram',
        'Web',
    ]);
    const [frequenciaEnvio, setFrequenciaEnvio] = useState('diario');
    const [loadList, setLoadList] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        changePage('name', 'Monitoramento Automatizado');
        getData();
    }, []);

    async function getData() {
        setLoadList(true);
        const response = await RequestAPI.get('PesquisaRedeSocial');
        setData([...response.data.ObjRetorno]);
        setLoadList(false);
    }

    async function handleSubmit(e) {
        try {
            e.preventDefault();

            const response = await RequestAPI.post('PesquisaRedeSocial', {
                PalavraChave: search,
                NomeBusca: searchName,
                Redes: sociais.join(',').toLowerCase(),
                DataInicio: dataInicio,
                DataFim: dataFinal,
                FrequenciaEnvio: frequenciaEnvio,
            });
            getData();
        } catch (err) {
            console.log(err);
        }
    }

    function handleChangeSocial(name) {
        if (sociais.filter((obj) => obj === name).length) {
            setSociais([...sociais.filter((obj) => obj != name)]);
        } else {
            sociais.push(name);
            setSociais([...sociais]);
        }
    }

    async function handleDelete(id) {
        let text = 'Você tem certeza que deseja remover essa pesquisa?';

        if (window.confirm(text) == true) {
            const response = await RequestAPI.post(
                'PesquisaRedeSocial?id=' + id
            );
            getData();
        }
    }

    return (
        <div id="monitoramento-automatizado">
            <form className="search-social" onSubmit={handleSubmit}>
                <h2>Criar uma nova pesquisa</h2>
                <h4>Selecione a origem da busca</h4>
                <div className="social-list">
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Facebook')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Facebook').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconFacebook />
                        <span>Facebook</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Twitter')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Twitter').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconTwitter />
                        <span>Twitter</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Youtube')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Youtube').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconYoutube />
                        <span>Youtube</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Instagram')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Instagram').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconInstagram />
                        <span>Instagram</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => handleChangeSocial('Web')}
                        className={`social ${
                            sociais.filter((obj) => obj === 'Web').length
                                ? 'active'
                                : 'inactive'
                        }`}
                    >
                        <IconWeb />
                        <span>Sites</span>
                    </button>
                </div>

                <div className="input-container">
                    <InputDefault
                        label="Nome da busca"
                        value={searchName}
                        onChange={(name, value) => setSearchName(value)}
                        placeholder="Digite aqui..."
                    />
                    <InputDefault
                        label="Palavra chave, para realizar a busca"
                        value={search}
                        onChange={(name, value) => setSearch(value)}
                        placeholder="Digite aqui..."
                    />
                    <div style={{ marginTop: 16 }}>
                        <ButtonDefault load={load}>Confirmar</ButtonDefault>
                    </div>
                </div>
            </form>

            <div className="table-default">
                <div className="head">
                    <p>Pesquisas salvas</p>
                </div>

                <table>
                    <tr>
                        <th>Visualizar</th>
                        <th>Remover</th>
                        <th>Nome da busca</th>
                        <th>Palavra chave</th>
                        <th>Origem</th>
                        <th>Data inicial</th>
                    </tr>

                    {loadList === true &&
                        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((row, key) => (
                            <tr>
                                <td colSpan={7}>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                    {loadList === false &&
                        data.map((row, key) => (
                            <tr key={key}>
                                <td>
                                    <ButtonDefault
                                        onClick={() =>
                                            history.push(
                                                '/monitoramento-social/' +
                                                    row.SearchId,
                                                {
                                                    data: row,
                                                }
                                            )
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 16 16"
                                        >
                                            <g fill="currentColor">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0z" />
                                            </g>
                                        </svg>
                                    </ButtonDefault>
                                </td>
                                <td>
                                    <ButtonDefault
                                        onClick={() =>
                                            handleDelete(row.SearchId)
                                        }
                                        className="danger"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                            role="img"
                                            width="1em"
                                            height="1em"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 16 16"
                                        >
                                            <g fill="currentColor">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                            </g>
                                        </svg>
                                    </ButtonDefault>
                                </td>
                                <td>{row.Nome}</td>
                                <td>{row.Busca}</td>
                                <td>
                                    {row.Redes.split(',').map((row, key) => (
                                        <span className="color-span">
                                            {row}
                                        </span>
                                    ))}
                                </td>
                                <td>{row.DataInicio}</td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    );
}
