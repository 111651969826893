import React from 'react';
import InputDefault from '../Form/InputDefault';

function Agendamento({DTO, handleOnChange}) {
  return (
    <div className="form-data col">
        
        <ul className="info">
          <li>Nessa etapa vamos concluir a campanha, então selecione um periodo inicial/final.</li>
        </ul>

        <InputDefault
            label="Periodo inicial"
            value={DTO.dataInicio}
            name="dataInicio"
            type="date"
            onChange={(name, value) => handleOnChange(name, value) }
        /> 
        <InputDefault
            label="Periodo final"
            value={DTO.dataFim}
            name="dataFim"
            type="date"
            onChange={(name, value) => handleOnChange(name, value) }
        /> 
    </div>
  );
}

export default Agendamento;